<template>
  <div class="home">
    <div class="cont">
			<div class="left">
				<div @click="changes(item.id)" class="name" v-for="(item,i) in content" :key="item.id" :class="{active:change == item.id}">{{item.name}}</div>
			</div>
			<div class="right" v-for="(item,i) in content" :key="item.id" v-if="change == item.id">
        <vue-scroll>
          <div v-html="item.content"></div>
        </vue-scroll>
			</div>
		</div>
  </div>
</template>
<script>
import router from '@/router'
import $ from 'jquery'
export default {
  data(){
    return{
      id:'',
      token:'',
      tokens:'',
      change:'',
      cc:'',
      content:[]
    }
  },
  created () {
  },
  mounted(){
    var dd= ''
    dd = window.location.search
    var index = dd.lastIndexOf("=")
    dd = dd.substring(index+1,dd.length);
    this.token = dd
    console.log(dd,"ddddddddd")

    // var aa = ''
    // aa = window.location.search
    // var aas = aa.split('=')[1].split('&')[0]
    // this.cc = aas
    // var index = aa.lastIndexOf("=")
    // aa = aa.substring(index+1,dd.length);
    // this.token = aa
    // console.log(aa,'this.cc') 
    // if(this.cc != '') {
    //   console.log('好的')
    //   this.$router.push({
    //     path:'/about',
    //     query:{
    //       id:this.cc,
    //       token:this.token
    //     }
    //   })
    // }

    $.ajax({
      // url: 'http://192.168.0.122:8100/xyx/consumer/helper/page',
      url: 'https://super.admin.woomedi.cn/xyx/consumer/helper/page',
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify({
        page:'1',
        size:'100',
        like:''
      }),
      headers:{
        token:this.token
      },    
      success: (res) => {
        if(res.code == 200) {
          this.content = res.data.content
          this.change = res.data.content[0].id
        }else if(res.code == 10004) {
          this.$message.info('账号已在其它手机上登陆，请重新登陆')
        }else if(res.code == 10000) {
          this.$message.info('账号失效')
        }else if(res.code == 20006) {
          this.$message.info('账号被停用')
        }
      }
    })
  },
  methods:{
    changes(id){
      this.change = id
    }
  }
}
</script>

<style scoped lang="less">
.body .html{
  background-color: red;
  width: 100%;
  height: 100%;
}

  .cont{
			font-size: 20px;
			width: 90%;
			margin: 20px auto 0;
			background-color: #FFFFFF;
			display: flex;
			justify-content: space-between;
			padding: 0 10px 10px 10px;
			.left{
				width: 30%;
				// width: 150px;
        overflow: hidden;
        white-space: nowrap;
				.name{
					height: 50px;
					line-height: 50px;
					text-align: center;
					color: #999999;
					margin-top: 20px;
				}
				.active{
					background-color: #459CF7;
					color: #FFFFFF;
					border-radius: 5px;
				}
			}
			.right{
				width: 65%;
				// width: 500px;
				margin-top: 20px;
        height: 100vh;
				color: #333333;
        image{
          width: 100% !important;
        }
			}
		}
    ::v-deep img{
     width: 100% !important;
    }
    ::v-deep video{
     width: 100% !important;
    }
    ::v-deep .__view{
      width: 0 !important;
    }
    ::v-deep .__bar-is-vertical{
          background:#ccc !important;
        }
</style>
