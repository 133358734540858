<template>
  <div class="home">
    <div class="cont">
			<div class="right">
        <vue-scroll>
          <div v-html="cont"></div>
        </vue-scroll>
			</div>
		</div>
  </div>
</template>
<script>
import router from '@/router'
import $ from 'jquery'
export default {
  data(){
    return{
      id:'',
      token:'',
      cc:'',
      change:'',
      content:[],
      cont:''
    }
  },
  created () {
  },
  mounted(){
    var dd = ''
    dd = window.location.search
    var dds = dd.split('=')[1].split('&')[0]
    this.cc = dds
    var index = dd.lastIndexOf("=")
    dd = dd.substring(index+1,dd.length);
    this.token = dd
    // this.token = this.$route.query.token
    // this.cc = this.$route.query.id
    $.ajax({
      // url: 'http://192.168.0.122:8100/xyx/consumer/helper/byId',
      url: 'https://super.admin.woomedi.cn/xyx/consumer/helper/byId',
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify({
       id:this.cc
      }),
      headers:{
        token:this.token
      },
      success: (res) => {
        if(res.code == 200) {
          this.cont = res.data.content
        }else if(res.code == 10004) {
          this.$message.info('账号已在其它手机上登陆，请重新登陆')
        }else if(res.code == 10000) {
          this.$message.info('账号失效')
        }else if(res.code == 20006) {
          this.$message.info('账号被停用')
        }
      }
    })
  },
}
</script>

<style scoped lang="less">
  .cont{
			font-size: 20px;
			width: 90%;
			margin: 20px auto 0;
			background-color: #FFFFFF;
			display: flex;
			justify-content: space-between;
			padding: 0 10px 10px 10px;
	}
  .right{
      width: 100%;
      margin-top: 20px;
      height: 100vh;
      color: #333333;
  }
  ::v-deep .__bar-is-vertical{
          background:#ccc !important;
        }
</style>
