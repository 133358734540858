<template>
  <div id="app">
    <!-- <router-view/> -->
    <About v-if="defug == 1"/>
    <Home v-if="defug == 2"/>
  </div>
</template>
<script>
import Home from '@/views/Home.vue'
import About from '@/views/AboutView.vue'
export default{
  data(){
    return{
      data:'',
      defug:''
    }
  },
  mounted(){
    this.data = window.location.search
    console.log(window.location.search,'window.location.search')
    console.log(this.data.includes('?id='),'ok')

    if(this.data.includes('?id=')){
      this.defug = 1
    }else {
      this.defug = 2
    }
  },
  components:{
    Home,About
  }
}
</script>

<style lang="less">
body{
  background-color: #f5f5f5;
}
</style>
