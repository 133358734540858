import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
//引入scroll
import vuescroll from 'vuescroll';
import ElementUI from 'element-ui';
Vue.use(vuescroll);
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
